import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { AccountCircle, Email, Phone, LocationOn } from "@mui/icons-material";

function Donate() {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit } = useForm();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axios.post("/api/createDonation", data);
      console.log(response.data);
      // Optionally, you can reset the form state or show a success message here
      handleClose(); // Close the modal after form submission
    } catch (error) {
      console.error("Error creating donation:", error);
      // Handle error or show error message to the user
    }
  };

  // Define custom theme for the form
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="min-h-screen text-white donate-section top-[-1]">
        <div className="container">
          <div className="text-center">
            <h1 className="font-bold text-4xl lg:text-5xl mb-10">
              Help Our Course
            </h1>
            <p className="text-2xl font-semibold lg:text-3xl">
              Your support and contributions will enable us to meet our goals
              and improve conditions. Your generous donation will fund our
              mission.
            </p>
            <Button
              size="large"
              sx={{ fontWeight: "bold", my: "20px" }}
              variant="contained"
              onClick={handleOpen}
            >
              Donate Us
            </Button>
          </div>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Donate Now</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)} className="donation-form">
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <AccountCircle color="primary" />,
                  }}
                  {...register("name")}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <Email color="primary" />,
                  }}
                  {...register("email")}
                />
                <TextField
                  fullWidth
                  label="Phone Number"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <Phone color="primary" />,
                  }}
                  {...register("phone_number")}
                />
                <TextField
                  fullWidth
                  label="Address"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <LocationOn color="primary" />,
                  }}
                  {...register("address")}
                />
                <TextField
                  fullWidth
                  label="City"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <LocationOn color="primary" />,
                  }}
                  {...register("city")}
                />
                <TextField
                  fullWidth
                  label="Amount"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <AccountCircle color="primary" />,
                  }}
                  {...register("amount")}
                />
                <TextField
                  fullWidth
                  label="Cause"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    startAdornment: <LocationOn color="primary" />,
                  }}
                  {...register("cause")}
                />
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  margin="normal"
                  multiline
                  rows={4}
                  {...register("description")}
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                sx={{ backgroundColor: "#1F0954", color: "#ffffff" }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Donate;

// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   Typography,
//   Grid,
//   Chip,
//   styled, // Import styled function
//   InputAdornment, // Import InputAdornment component
//   IconButton, // Import IconButton component
// } from "@mui/material";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; // Import the Money icon
// import GoogleIcon from "@mui/icons-material/Google"; // Import the Google icon

// const DonationContainer = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   padding: "16px",
//   backgroundColor: "#fff",
//   borderRadius: "8px",
//   boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
//   minWidth: "300px",
// });

// const DonationButton = styled(Button)({
//   margin: "8px",
// });

// const DonationComponent = () => {
//   const [open, setOpen] = useState(false);
//   const [donationAmount, setDonationAmount] = useState(1); // Default donation amount is $1

//   const handleOpen = (amount) => {
//     setOpen(true);
//     setDonationAmount(amount);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleCustomDonation = () => {
//     // Perform custom donation action
//     console.log("Custom donation:", donationAmount);
//     handleClose();
//   };

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//       }}
//     >
//       <DonationContainer>
//         <Typography variant="h6" gutterBottom>
//           {/* Write your content here */}
//           Donate
//         </Typography>
//         <Grid container spacing={2} justify="center">
//           <Grid item xs={12} container justifyContent="center">
//             {[25, 50].map((amount) => (
//               <Grid item key={amount}>
//                 <Chip
//                   label={${amount}}
//                   clickable
//                   color="primary"
//                   onClick={() => handleOpen(amount)}
//                   style={{
//                     padding: "20px",
//                     marginRight: "20px",
//                     width: "150px",
//                   }}
//                   padding="20px"
//                 />
//               </Grid>
//             ))}
//           </Grid>
//           <Grid item xs={12} container justifyContent="center">
//             {[150, 200].map((amount) => (
//               <Grid item key={amount}>
//                 <Chip
//                   label={$${amount}}
//                   clickable
//                   color="primary"
//                   onClick={() => handleOpen(amount)}
//                   style={{
//                     padding: "20px",
//                     marginRight: "20px",
//                     width: "150px",
//                   }}
//                   padding="20px"
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Grid>

//         <DonationButton
//           variant="contained"
//           color="primary"
//           onClick={() => handleOpen()}
//         >
//           Donate
//         </DonationButton>
//         <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
//           <DialogTitle>Donation Amount</DialogTitle>
//           <DialogContent>
//             <Typography gutterBottom>
//               Donation Amount: ${donationAmount}
//             </Typography>

//             <TextField
//               label="Custom Amount"
//               variant="outlined"
//               type="number"
//               value={donationAmount} // Set default value to 1
//               onChange={(e) =>
//                 setDonationAmount(Math.max(parseInt(e.target.value), 1))
//               }
//               fullWidth
//               margin="normal"
//               InputProps={{
//                 // Add InputProps
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <AttachMoneyIcon /> {/* Money icon */}
//                   </InputAdornment>
//                 ),
//               }}
//               inputProps={{ min: 1 }} // Set minimum value for input field
//             />
//             <Grid container spacing={1}>
//               {[25, 50, 150, 200].map((amount) => (
//                 <Grid item key={amount}>
//                   <Chip
//                     label={$${amount}}
//                     clickable
//                     color="primary"
//                     onClick={() => handleOpen(amount)}
//                   />
//                 </Grid>
//               ))}
//             </Grid>
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               marginBottom="16px"
//               flexDirection="column"
//               paddingTop="16px"
//             >
//               <Button
//                 variant="contained"
//                 fullWidth
//                 // color="primary"
//                 style={{
//                   marginRight: "8px",
//                   marginBottom: "8px",
//                   backgroundColor: "white",
//                   border: "1px solid black",
//                 }}
//               >
//                 <GoogleIcon style={{ color: "black" }} />
//                 <Typography style={{ color: "black", padding: "2px" }}>
//                   pay
//                 </Typography>
//               </Button>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//                 style={{ marginRight: "8px", marginBottom: "8px" }}
//               >
//                 PayPal
//               </Button>
//               <Button
//                 variant="contained"
//                 fullWidth
//                 color="primary"
//                 style={{ marginRight: "8px", marginBottom: "8px" }}
//               >
//                 Credit/Debit
//               </Button>
//             </Box>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>Cancel</Button>
//             <Button onClick={handleCustomDonation} color="primary">
//               Donate
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </DonationContainer>
//     </div>
//   );
// };

// export default DonationComponent;
