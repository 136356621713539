import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Button,
  Paper,
  Modal,
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
} from "@mui/material";
import { styled } from "@mui/system"; // Import styled from @mui/system
import { Delete, Edit } from "@mui/icons-material";

const StyledCard = styled(Card)({
  maxWidth: 345,
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
  },
});

const StyledModalPaper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '8px',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CourseManagement = () => {
  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [newCourse, setNewCourse] = useState({ title: '', description: '' });

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await axios.get('http://projectarthabackend.ajinkyatechnologies.com/api/course/get-courses');
      setCourses(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const handleCreateCourse = async () => {
    try {
      const response = await axios.post('http://projectarthabackend.ajinkyatechnologies.com/api/course', newCourse);
      console.log('Course created:', response.data);
      fetchCourses();
      setShowModal(false);
      setNewCourse({ title: '', description: '' });
    } catch (error) {
      console.error('Error creating course:', error);
    }
  };

  const handleEditCourse = async () => {
    try {
      const response = await axios.put(`http://projectarthabackend.ajinkyatechnologies.com/api/course/${selectedCourse.id}`, selectedCourse);
      console.log('Course updated:', response.data);
      fetchCourses();
      setShowModal(false);
      setSelectedCourse(null);
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      await axios.delete(`http://projectarthabackend.ajinkyatechnologies.com/api/course/${id}`);
      console.log('Course deleted');
      fetchCourses();
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Course Management
      </Typography>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained"
        color="primary"
        style={{ float: 'right', marginBottom: '20px' }}
      >
        Create Course
      </Button>

      <Grid container spacing={3}>
        {courses.map(course => (
          <Grid item xs={12} sm={6} md={4} key={course.id}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {course.title}
                </Typography>
                <Typography variant="body2" component="p">
                  {course.description}
                </Typography>
              </CardContent>
              <CardActions>
                <IconButton aria-label="edit" onClick={() => { setSelectedCourse(course); setShowModal(true); }}>
                  <Edit />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => handleDeleteCourse(course.id)}>
                  <Delete />
                </IconButton>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
      >
        <DialogTitle>{selectedCourse ? 'Edit Course' : 'Create Course'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={selectedCourse ? selectedCourse.title : newCourse.title}
            onChange={(e) => {
              if (selectedCourse) {
                setSelectedCourse({ ...selectedCourse, title: e.target.value });
              } else {
                setNewCourse({ ...newCourse, title: e.target.value });
              }
            }}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={selectedCourse ? selectedCourse.description : newCourse.description}
            onChange={(e) => {
              if (selectedCourse) {
                setSelectedCourse({ ...selectedCourse, description: e.target.value });
              } else {
                setNewCourse({ ...newCourse, description: e.target.value });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={selectedCourse ? handleEditCourse : handleCreateCourse} color="primary">
            {selectedCourse ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseManagement;
