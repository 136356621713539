import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        bgcolor: "#1F0954",
        p: 2,
        textAlign: "center",
        color: "#fff", // Text color
        fontFamily: "Arial, sans-serif", // Font family
      }}
    >
      <Typography variant="subtitle2" gutterBottom>
        Quick Links:
        <Link
          to="/"
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            textDecoration: "none",
            fontWeight: "bold", // Added font weight
          }}
        >
          Home
        </Link>
        <Link
          to="/aboutus"
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            textDecoration: "none",
            fontWeight: "bold", // Added font weight
          }}
        >
          About
        </Link>
        <Link
          to="/contact"
          style={{
            marginLeft: "8px",
            marginRight: "8px",
            textDecoration: "none",
            fontWeight: "bold", // Added font weight
          }}
        >
          Contact
        </Link>
      </Typography>
      <Typography variant="caption" color="white">
        &copy; {new Date().getFullYear()} Ajinkya Technologies | All rights
        reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
