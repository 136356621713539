import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  AccountCircle,
  Email,
  Lock,
  LocationOn,
  Phone,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fade from "@mui/material/Fade";
import Title from "../Title/Title";

const apiUrl = "https://projectarthabackend.ajinkyatechnologies.com/api/student";

const StudentManagement = () => {
  const [students, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [studentFormData, setStudentFormData] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    mobile_number: "",
  });
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedStudentToDelete, setSelectedStudentToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    try {
      const response = await axios.post(`${apiUrl}/get-students`);
      setStudents(response.data);
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const handleAddStudent = () => {
    setSelectedStudentId(null);
    setOpenDialog(true);
    // Reset form fields
    setStudentFormData({
      name: "",
      email: "",
      password: "",
      address: "",
      mobile_number: "",
    });
  };

  const handleEditStudent = async (studentId) => {
    setSelectedStudentId(studentId);
    setOpenDialog(true);
    try {
      const response = await axios.post(`${apiUrl}/get-students/${studentId}`);
      setStudentFormData(response.data);
    } catch (error) {
      console.error("Error fetching student:", error);
    }
  };

  const handleCloseDialog = () => {
    setSelectedStudentId(null);
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteStudent = (studentId) => {
    setSelectedStudentToDelete(studentId);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteStudent = async () => {
    try {
      await axios.delete(
        `${apiUrl}/delete-students/${selectedStudentToDelete}`
      );
      fetchStudents();
      toast.success("Student deleted successfully");
    } catch (error) {
      console.error("Error deleting student:", error);
      toast.error("Error deleting student");
    }
    setOpenDeleteDialog(false);
  };

  const handleFormChange = (e, fieldName) => {
    const value = e.target.value;
    setStudentFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleAddStudentSubmit = async () => {
    try {
      let endpoint = `${apiUrl}/add-student`;
      let method = "POST";
      let successMessage = "";

      if (selectedStudentId) {
        endpoint = `${apiUrl}/update-students/${selectedStudentId}`;
        method = "PUT";
        successMessage = "Student updated successfully";
      } else {
        successMessage = "Student added successfully";
      }

      const response = await axios({
        method: method,
        url: endpoint,
        data: studentFormData,
      });
      console.log();
      if (response.status === 201 || response.status === 200) {
        fetchStudents();
        setOpenDialog(false);
        toast.success(successMessage);
      } else {
        toast.error("Error adding or updating student");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error adding or updating student");
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://projectarthabackend.ajinkyatechnologies.com/api/search/search?query=${searchTerm}`
      );
      setStudents(response.data.student);
    } catch (error) {
      console.error("Error searching media", error);
      // setError("Error searching media. Please try again.");
    }
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title title={"Student Management"} className="mx-24"></Title>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "95%",
          }}
        >
          <div className="flex items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Student"
              className="mr-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 mx-4 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
            >
              Search
            </button>
          </div>
          <Button
            variant="contained"
            color="primary"
            // style={{ position: "absolute", top: 80, right: 10 }}
            startIcon={<AddIcon />}
            onClick={handleAddStudent}
          >
            Add Student
          </Button>
        </div>

        <div className="flex justify-center">
          <TableContainer component={Paper} style={{ width: "90%" }}>
            <Table style={{ minWidth: 650 }} aria-label="students table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, #4F46E5 0%, #7A5CEB 100%)",
                  }}
                >
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Name
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Email
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Address
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Phone
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <Fade in={students} timeout={500}>
                <TableBody>
                  {students.length === 0 ? (
                    <TableRow style={{ height: "200px" }}>
                      <TableCell colSpan={5} align="center">
                        <h1 style={{ fontSize: "25px" }}>No Data Found</h1>
                      </TableCell>
                    </TableRow>
                  ) : (
                    students
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((student) => (
                        <TableRow key={student.student_id}>
                          <TableCell>{student.name}</TableCell>
                          <TableCell>{student.email}</TableCell>
                          <TableCell>{student.address}</TableCell>
                          <TableCell>{student.mobile_number}</TableCell>
                          <TableCell>
                            <Button
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() =>
                                handleEditStudent(student.student_id)
                              }
                            >
                              Edit
                            </Button>
                            <IconButton
                              aria-label="delete"
                              color="black"
                              onClick={() =>
                                handleDeleteStudent(student.student_id)
                              }
                            >
                              <DeleteIcon className="text-black" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Fade>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={students.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedStudentId ? "Update Student" : "Add Student"}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={studentFormData.name}
              onChange={(e) => handleFormChange(e, "name")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20, marginTop: 20 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={studentFormData.email}
              onChange={(e) => handleFormChange(e, "email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
            {!selectedStudentId && (
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={studentFormData.password}
                onChange={(e) => handleFormChange(e, "password")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: 20 }}
              />
            )}
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={studentFormData.address}
              onChange={(e) => handleFormChange(e, "address")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOn color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              value={studentFormData.mobile_number} // Change 'phone' to 'mobile_number'
              onChange={(e) => handleFormChange(e, "mobile_number")} // Change 'phone' to 'mobile_number'
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddStudentSubmit} color="primary">
              {selectedStudentId ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Delete Student</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this student?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteStudent} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default StudentManagement;
