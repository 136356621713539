import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
  Link,
} from "@mui/material";
function Education() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ padding: "16px", background: "#E6EDFF", minHeight: "100vh" }}
      >
        <h1 className="text-center font-bold text-5xl my-5">Education</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="images/KhanAcademy.jpg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Khan Academy
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained">
                  <Link
                    href="https://www.khanacademy.org/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Explore
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="images/financeIndependent.jpg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Become Financial Independent
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained">
                  <Link
                    href="https://www.youtube.com/watch?v=q5JWp47z4bY"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Explore
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Add more Grid items for additional resources */}
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Education;
