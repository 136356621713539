import React from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  ThemeProvider,
  createTheme,
  CardMedia,
  CardActions,
  CardActionArea,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

// import { styled } from "@mui/system";
// const ResourceCard = styled(Card)(({ theme }) => ({
//   height: "100%",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "space-between",
//   borderRadius: theme.spacing(2),
//   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//   transition: "transform 0.3s ease",
//   "&:hover": {
//     transform: "translateY(-5px)",
//   },
// }));

function Resources() {
  // Define custom theme for the resource section
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        className="text-center"
        style={{ padding: "16px", background: "#E6EDFF", minHeight: "100vh" }}
      >
        <h1 className="font-bold text-5xl my-5">Resources</h1>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="images/finance1.jpg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Finance
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained">
                  <Link
                    to="/finance"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Explore
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="images/education.jpg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Education
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained">
                  <Link
                    to="/education"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Explore
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="images/employment1.jpg"
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Employment
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" variant="contained">
                  <Link
                    to="/employment"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Explore
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>

          {/* Add more Grid items for additional resources */}
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Resources;
