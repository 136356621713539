import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { AccountCircle, Email, Lock, Phone } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fade from "@mui/material/Fade";
import Title from "../Title/Title";

const apiUrl = "https://projectarthabackend.ajinkyatechnologies.com/api/admin";

const AdminManagement = () => {
  const [admins, setAdmins] = useState([]); //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [adminFormData, setAdminFormData] = useState({
    username: "",
    email: "",
    password: "",
    mobile_number: "",
  });
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedAdminToDelete, setSelectedAdminToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    try {
      const response = await axios.post(`${apiUrl}/get-admin`);

      setAdmins(response.data.data);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  };

  const handleAddAdmin = () => {
    setSelectedAdminId(null);
    setOpenDialog(true);
    // Reset form fields
    setAdminFormData({
      username: "",
      email: "",
      password: "",
      mobile_number: "",
    });
  };

  const handleEditAdmin = async (adminId) => {
    setSelectedAdminId(adminId);
    setOpenDialog(true);
    try {
      const response = await axios.post(`${apiUrl}/get-admin-id/${adminId}`);
      setAdminFormData(response.data);
    } catch (error) {
      console.error("Error fetching admin:", error);
    }
  };

  const handleCloseDialog = () => {
    setSelectedAdminId(null);
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteAdmin = (adminId) => {
    setSelectedAdminToDelete(adminId);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteAdmin = async () => {
    try {
      await axios.delete(`${apiUrl}/delete-admin/${selectedAdminToDelete}`);
      fetchAdmins();
      toast.success("Admin deleted successfully");
    } catch (error) {
      console.error("Error deleting admin:", error);
      toast.error("Error deleting admin");
    }
    setOpenDeleteDialog(false);
  };

  const handleFormChange = (e, fieldName) => {
    const value = e.target.value;
    setAdminFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleAddAdminSubmit = async () => {
    try {
      let endpoint = `${apiUrl}/admin`;
      let method = "POST";
      let successMessage = "";

      if (selectedAdminId) {
        endpoint = `${apiUrl}/update-admin/${selectedAdminId}`;
        method = "PUT";
        successMessage = "Admin updated successfully";
      } else {
        successMessage = "Admin added successfully";
      }

      const response = await axios({
        method: method,
        url: endpoint,
        data: adminFormData,
      });

      if (response.status === 201 || response.status === 200) {
        fetchAdmins();
        setOpenDialog(false);
        toast.success(successMessage);
      } else {
        toast.error("Error adding or updating admin");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error adding or updating admin");
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://projectarthabackend.ajinkyatechnologies.com/api/search/search?query=${searchTerm}`
      );
      setAdmins(response.data.admin);
    } catch (error) {
      console.error("Error searching media", error);
      // setError("Error searching media. Please try again.");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title title={"Admin Management"} className="mx-24"></Title>
      </div>
      <div
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "20px", 
            marginBottom: "20px",
            width: "95%",
           
          }}
        >
          <div className="flex items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Admin"
              className="mr-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 mx-4 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
            >
              Search
            </button>
          </div>
          <Button
            variant="contained"
            color="primary"
            // style={{ position: "absolute", top: 80, right: 10 }}
            startIcon={<AddIcon />}
            onClick={handleAddAdmin}
          >
            Add Admin
          </Button>
        </div>
        <div className="flex justify-center">
          <TableContainer component={Paper} style={{ width: "90%" }}>
            <Table style={{ minWidth: 650 }} aria-label="admins table">
              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, #4F46E5 0%, #7A5CEB 100%)",
                  }}
                >
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Username
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Email
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Phone
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <Fade in={admins} timeout={500}>
                <TableBody>
                  {admins.length === 0 ? (
                    <TableRow style={{ height: "200px" }}>
                      <TableCell colSpan={4} align="center">
                        <h1 style={{ fontSize: "25px" }}>No Data Found</h1>
                      </TableCell>
                    </TableRow>
                  ) : (
                    admins
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((admin) => (
                        <TableRow key={admin.admin_id}>
                          <TableCell>{admin.username}</TableCell>
                          <TableCell>{admin.email}</TableCell>
                          <TableCell>{admin.mobile_number}</TableCell>
                          <TableCell>
                            <Button
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() => handleEditAdmin(admin.admin_id)}
                            >
                              Edit
                            </Button>
                            <IconButton
                              aria-label="delete"
                              color="black"
                              onClick={() => handleDeleteAdmin(admin.admin_id)}
                            >
                              <DeleteIcon className="text-black" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Fade>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={admins.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedAdminId ? "Update Admin" : "Add Admin"}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={adminFormData.username}
              onChange={(e) => handleFormChange(e, "username")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20, marginTop: 20 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={adminFormData.email}
              onChange={(e) => handleFormChange(e, "email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
            {!selectedAdminId && (
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={adminFormData.password}
                onChange={(e) => handleFormChange(e, "password")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: 20 }}
              />
            )}
            <TextField
              label="Phone"
              variant="outlined"
              fullWidth
              value={adminFormData.mobile_number}
              onChange={(e) => handleFormChange(e, "mobile_number")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddAdminSubmit} color="primary">
              {selectedAdminId ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Delete Admin</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this admin?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteAdmin} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default AdminManagement;
