import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Typography,
  Link,
  Paper,
  InputAdornment,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  AccountCircle,
  Email,
  Lock,
  LocationOn,
  Phone,
} from "@mui/icons-material";
import axios from "axios";

const MentorRegister = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onSubmit = async (data) => {
    try {
      // Make API request to register mentor
      const response = await axios.post("http://projectarthabackend.ajinkyatechnologies.com/api/mentor/add-mentors", data);
      console.log(response.data); // Assuming response contains success message
      handleOpenModal(); // Open success modal on successful registration
      reset(); // Reset form fields
    } catch (error) {
      console.error("Error registering mentor:", error);
      // Handle error or display error message to the user
    }
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000", // Black color
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          maxWidth: "600px",
          margin: "auto",
          marginTop: "50px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Register as a Mentor
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...register("name", { required: "Name is required" })}
              />
              <Typography variant="body2" color="error">
                {errors.name && errors.name.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              <Typography variant="body2" color="error">
                {errors.email && errors.email.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 8,
                    message: "Password must be at least 8 characters long",
                  },
                })}
              />
              <Typography variant="body2" color="error">
                {errors.password && errors.password.message}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Mobile Number"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...register("mobile_number", {
                  required: "Mobile number is required",
                  pattern: {
                    value: /^\d{10}$/,
                    message: "Invalid mobile number",
                  },
                })}
              />
              <Typography variant="body2" color="error">
                {errors.mobile_number && errors.mobile_number.message}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...register("address", { required: "Address is required" })}
              />
              <Typography variant="body2" color="error">
                {errors.address && errors.address.message}
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            type="submit"
            fullWidth
            style={{ backgroundColor: "#1F0954", color: "#ffffff", marginTop: "20px" }}
          >
            Register
          </Button>
        </form>
        <Box mt={2}>
          <Typography variant="body2">
            Already have an account?{" "}
            <Link href="/mentorlogin" style={{ color: "#1F0954", fontWeight: "bold" }}>
              Login here
            </Link>
          </Typography>
        </Box>
      </Paper>

      {/* Success Modal */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Registration Successful</DialogTitle>
        <DialogContent>
          <Typography>Your mentor registration has been successfully completed.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default MentorRegister;
