import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Link, Paper, InputAdornment, ThemeProvider, createTheme, Modal, Box } from "@mui/material";
import { Email, Lock } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';

const MentorLogin = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1F0954", // Your primary color
      },
    },
    typography: {
      fontFamily: 'Arial, sans-serif',
    },
  });

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleLogin = () => {
    axios.post('http://projectarthabackend.ajinkyatechnologies.com/api/mentor/mentors/login', { email, password })
      .then(response => {
        setSuccessModalOpen(true);
        // Redirect to '/mentor-dashboard' after 2 seconds
        setTimeout(() => navigate('/mentor-dashboard'), 2000);
      })
      .catch(error => {
        setError('Invalid email or password. Please try again.');
      });
  };

  const handleModalClose = () => {
    setSuccessModalOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          maxWidth: "400px",
          margin: "auto",
          marginTop: "50px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Mentor Sign in
        </Typography>
        <form>
          <TextField
            label="Your email"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email color="primary" />
                </InputAdornment>
              ),
            }}
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock color="primary" />
                </InputAdornment>
              ),
            }}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <Typography variant="body2" style={{ color: 'red', marginBottom: '10px' }}>{error}</Typography>}
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input id="remember" aria-describedby="remember" type="checkbox" required />
              <label htmlFor="remember" style={{ marginLeft: "5px" }}>Remember me</label>
            </div>
            <Link href="#" style={{ color: "#1F0954", fontSize: "14px" }}>Forgot password?</Link>
          </div>
          <Button
            variant="contained"
            fullWidth
            style={{ backgroundColor: "#1F0954", color: "#ffffff", marginBottom: "10px" }}
            onClick={handleLogin}
          >
            Sign in
          </Button>
          <Typography variant="body2" style={{ textAlign: "center" }}>
            Don’t have an account yet? <Link href="#" style={{ color: "#1F0954", fontWeight: "bold" }}>Sign up</Link>
          </Typography>
        </form>
      </Paper>

      {/* Success Modal */}
      <Modal
        open={successModalOpen}
        onClose={handleModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '300px',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6" id="success-modal-title" gutterBottom>
            Login Successful
          </Typography>
          <Typography variant="body1" id="success-modal-description">
            Redirecting to mentor dashboard...
          </Typography>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default MentorLogin;
