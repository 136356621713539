import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <div className="lg:min-h-screen hero-section text-center lg:pt-20">
        <Grid
          container
          alignItems="center"
          className="text-center container justify-center pt-5"
        >
          <Grid item xs={12} md={8} className="lg:pt-20">
            <Typography
              color="#226575"
              variant="h3"
              className="lg:text-4xl"
              sx={{ fontWeight: "bold", mb: 5 }}
            >
              Welcome to Project Artha
            </Typography>

            <h3 className="font-bold text-2xl lg:my-5 md:text-3xl">
              Empowering all with financial literacy for economic prosperity.
            </h3>
            <h4 className="font-bold lg:my-5 text-2xl md:text-3xl">
              Join us in breaking barriers and building equality.
            </h4>
          </Grid>
          <Grid item md={4} xs={10}>
            <img src="/images/Logo.png" alt="Logo" />
          </Grid>
        </Grid>
      </div>

      <div className="lg:min-h-50% text-center  pt-5 bg-[#E6EDFF]">
        <Grid container className="container">
          <Grid md={0}></Grid>
          <Grid
            md={5.5}
            xs={12}
            className="vm-card md:ml-5 rounded-lg mt-2"
            sx={{ backgroundColor: "#fff", p: 2 }}
          >
            <div>
              <Typography
                variant="h5"
                color="#1F0954"
                sx={{ fontWeight: "bold", mb: 2 }}
              >
                VISION
              </Typography>
              <Typography variant="body1">
                Project Artha aims to empower young women by equipping them with
                the necessary skills to practice artha throughout their lives.
              </Typography>
            </div>
          </Grid>
          <Grid
            md={5.5}
            xs={12}
            className="md:ml-5 vm-card rounded-lg mt-2"
            sx={{ backgroundColor: "#fff", p: 2 }}
          >
            <Typography
              color="#1F0954"
              variant="h5"
              sx={{ fontWeight: "bold", mb: 2 }}
            >
              MISSION
            </Typography>
            <Typography variant="body1">
              Rooted in the profound principles of artha, we are committed to
              transforming the lives of youth by equipping them with financial
              literacy. Our volunteers embark on mission trips to schools in
              underprivileged regions around the world, where they teach a
              comprehensive curriculum on financial literacy. Our commitment
              doesn't end with these trips; we maintain an ongoing relationship
              with our students by providing mentorship, and supporting them as
              they navigate adulthood. Project Artha strives to foster an
              environment where all youth can sustain their financial means.
            </Typography>
          </Grid>

          <div className="container text-center mb-20 mt-8">
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3 }}>
              Help Project Artha make a difference
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              Our organization is committed to teaching young women about
              finances. With your support, we can make a positive impact on the
              world. Join us today!
            </Typography>
            <Button
              size="large"
              variant="contained"
              sx={{ bgcolor: "#6F00FE", mb: 2 }}
            >
              Find out more
            </Button>
          </div>
        </Grid>
      </div>

      <div className=" container my-20 text-center ">
        <Grid container>
          <Grid item xs={12} md={6} className="">
            <Typography
              variant="body"
              className="text-xl lg:text-3xl"
              sx={{ fontWeight: "bold", mb: 5 }}
            >
              Become part of Project Artha
            </Typography>

            {/* <div>
              <Link to="/mentorlogin" style={{ textDecoration: "none" }}>
                <Button
                  size="large"
                  variant="contained"
                  className="lg:my-10 my-5"
                  sx={{ bgcolor: "#6F00FE", my: 2 }}
                >
                  Login as Mentor
                </Button>
              </Link>
            </div>
            <div>
              <Link to="/mentorregister" style={{ textDecoration: "none" }}>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ bgcolor: "#6F00FE", mb: 2 }}
                >
                  Sign up as mentor
                </Button>
              </Link>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/mentorlogin"
                style={{ textDecoration: "none", width: "200px" }}
              >
                <Button
                  size="large"
                  variant="contained"
                  className="lg:my-10 my-5 custom-button"
                  sx={{ bgcolor: "#6F00FE", my: 2 }}
                >
                  Login as Mentor
                </Button>
              </Link>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                to="/mentorregister"
                style={{ textDecoration: "none", width: "200px" }}
              >
                <Button
                  size="large"
                  variant="contained"
                  className="custom-button"
                  sx={{ bgcolor: "#6F00FE", mb: 2 }}
                >
                  Sign up as mentor
                </Button>
              </Link>
            </div>
            {/* <div className="ml-[9rem] w-[16rem] become_part">
              <img src="/images/become_part.png" alt="" />
            </div> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Carousel autoPlay={true} animation="slide">
              <img src="/images/caro1.jpg" alt="" />
              <img src="/images/caro2.jpg" alt="" />
              <img src="/images/caro3.jpg" alt="" />
              <img src="/images/caro4.jpg" alt="" />

              {/* Add more carousel items as needed */}
            </Carousel>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
