// import React, { useState, useEffect } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   IconButton,
//   TablePagination,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   FormControl,
//   InputLabel,
//   Input,
//   FormHelperText,
// } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import { InputAdornment } from "@mui/material";
// import {
//   AccountCircle,
//   Email,
//   Lock,
//   LocationOn,
//   Phone,
// } from "@mui/icons-material";

// import { toast } from "react-toastify";

// const apiUrl = "https://projectarthabackend.ajinkyatechnologies.com/api/mentor";

// const MentorManagement = () => {
//   const [mentors, setMentors] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [mentorFormData, setMentorFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     address: "",
//     mobile_number: "",
//   });
//   const [selectedMentorId, setSelectedMentorId] = useState(null);

//   useEffect(() => {
//     fetchMentors();
//   }, []);

//   const fetchMentors = async () => {
//     try {
//       const response = await fetch(
//         `https://projectarthabackend.ajinkyatechnologies.com/api/mentor/get-mentors`
//       );
//       console.log("Resonsee :", response);
//       const data = await response.json();
//       setMentors(data);
//     } catch (error) {
//       console.error("Error fetching mentors:", error);
//     }
//   };

//   const handleAddMentor = () => {
//     setSelectedMentorId(null);
//     setOpenDialog(true);
//   };

//   const handleEditMentor = (mentorId) => {
//     setSelectedMentorId(mentorId);
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setSelectedMentorId(null);
//     setOpenDialog(false);
//   };
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleDeleteMentor = async (mentorId) => {
//     try {
//       await fetch(`${apiUrl}/delete-mentors/${mentorId}`, {
//         method: "DELETE",
//       });
//       fetchMentors();
//       toast.success("Mentor deleted successfully");
//     } catch (error) {
//       console.error("Error deleting mentor:", error);
//       toast.error("Error deleting mentor");
//     }
//   };

//   const handleFormChange = (e) => {
//     const { name, value } = e.target;
//     setMentorFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleAddMentorSubmit = async () => {
//     try {
//       let endpoint = `${apiUrl}/add-mentors`;
//       let method = "POST";
//       if (selectedMentorId) {
//         endpoint = `${apiUrl}/update-mentors/${selectedMentorId}`;
//         method = "PUT";
//       }

//       const response = await fetch(endpoint, {
//         method,
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(mentorFormData),
//       });

//       if (response.ok) {
//         fetchMentors();
//         setOpenDialog(false);
//         toast.success(
//           selectedMentorId
//             ? "Mentor updated successfully"
//             : "Mentor added successfully"
//         );
//       } else {
//         toast.error(
//           selectedMentorId ? "Error updating mentor" : "Error adding mentor"
//         );
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       toast.error(
//         selectedMentorId ? "Error updating mentor" : "Error adding mentor"
//       );
//     }
//   };

//   return (
//     <div style={{ marginTop: "80px" }}>
//       <Button
//         variant="contained"
//         color="primary"
//         style={{ position: "absolute", top: 80, right: 10 }}
//         startIcon={<AddIcon />}
//         onClick={handleAddMentor}
//       >
//         Add Mentor
//       </Button>
//       <TableContainer component={Paper}>
//         <Table style={{ minWidth: 650 }} aria-label="mentors table">
//           <TableHead>
//             <TableRow>
//               <TableCell>Name</TableCell>
//               <TableCell>Email</TableCell>
//               <TableCell>Address</TableCell>
//               <TableCell>Mobile Number</TableCell>
//               <TableCell>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {mentors
//               .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//               .map((mentor) => (
//                 <TableRow key={mentor.mentor_id}>
//                   <TableCell>{mentor.name}</TableCell>
//                   <TableCell>{mentor.email}</TableCell>
//                   <TableCell>{mentor.address}</TableCell>
//                   <TableCell>{mentor.mobile_number}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       aria-label="edit"
//                       onClick={() => handleEditMentor(mentor.mentor_id)}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                     <IconButton
//                       aria-label="delete"
//                       onClick={() => handleDeleteMentor(mentor.mentor_id)}
//                     >
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={mentors.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage} // Add onPageChange prop
//           onChangeRowsPerPage={handleChangeRowsPerPage}
//         />
//       </TableContainer>
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>
//           {selectedMentorId ? "Update Mentor" : "Add Mentor"}
//         </DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Name"
//             variant="outlined"
//             fullWidth
//             value={mentorFormData.name}
//             onChange={(e) => handleFormChange(e, "name")}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <AccountCircle color="primary" />
//                 </InputAdornment>
//               ),
//             }}
//             style={{ marginBottom: 20 }}
//           />
//           <TextField
//             label="Email"
//             variant="outlined"
//             fullWidth
//             value={mentorFormData.email}
//             onChange={(e) => handleFormChange(e, "email")}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <Email color="primary" />
//                 </InputAdornment>
//               ),
//             }}
//             style={{ marginBottom: 20 }}
//           />
//           <TextField
//             label="Password"
//             type="password"
//             variant="outlined"
//             fullWidth
//             value={mentorFormData.password}
//             onChange={(e) => handleFormChange(e, "password")}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <Lock color="primary" />
//                 </InputAdornment>
//               ),
//             }}
//             style={{ marginBottom: 20 }}
//           />
//           <TextField
//             label="Address"
//             variant="outlined"
//             fullWidth
//             value={mentorFormData.address}
//             onChange={(e) => handleFormChange(e, "address")}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <LocationOn color="primary" />
//                 </InputAdornment>
//               ),
//             }}
//             style={{ marginBottom: 20 }}
//           />
//           <TextField
//             label="Mobile Number"
//             variant="outlined"
//             fullWidth
//             value={mentorFormData.mobile_number}
//             onChange={(e) => handleFormChange(e, "mobile_number")}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <Phone color="primary" />
//                 </InputAdornment>
//               ),
//             }}
//             style={{ marginBottom: 20 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleAddMentorSubmit} color="primary">
//             {selectedMentorId ? "Update" : "Add"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default MentorManagement;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {
  AccountCircle,
  Email,
  Lock,
  LocationOn,
  Phone,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fade from "@mui/material/Fade";
import Title from "../Title/Title";
const apiUrl = "https://projectarthabackend.ajinkyatechnologies.com/api/mentor";

const MentorManagement = () => {
  const [mentors, setMentors] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [mentorFormData, setMentorFormData] = useState({
    name: "",
    email: "",
    password: "",
    address: "",
    mobile_number: "",
  });
  const [selectedMentorId, setSelectedMentorId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedMentorToDelete, setSelectedMentorToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchMentors();
  }, []);

  const fetchMentors = async () => {
    try {
      const response = await axios.post(`${apiUrl}/get-mentors`);
      console.log("response is : ", response);
      setMentors(response.data);
    } catch (error) {
      console.error("Error fetching mentors:", error);
    }
  };

  const handleAddMentor = () => {
    setSelectedMentorId(null);
    setOpenDialog(true);
    // Reset form fields
    setMentorFormData({
      name: "",
      email: "",
      password: "",
      address: "",
      mobile_number: "",
    });
  };

  const handleEditMentor = async (mentorId) => {
    setSelectedMentorId(mentorId);
    setOpenDialog(true);
    console.log(mentorId);
    try {
      const response = await axios.post(`${apiUrl}/get-mentors/${mentorId}`);
      setMentorFormData(response.data);
    } catch (error) {
      console.error("Error fetching mentor:", error);
    }
  };

  const handleCloseDialog = () => {
    setSelectedMentorId(null);
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteMentor = (mentorId) => {
    setSelectedMentorToDelete(mentorId);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteMentor = async () => {
    try {
      await axios.delete(`${apiUrl}/delete-mentors/${selectedMentorToDelete}`);
      fetchMentors();
      toast.success("Mentor deleted successfully");
    } catch (error) {
      console.error("Error deleting mentor:", error);
      toast.error("Error deleting mentor");
    }
    setOpenDeleteDialog(false);
  };

  const handleFormChange = (e, fieldName) => {
    const value = e.target.value;
    setMentorFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleAddMentorSubmit = async () => {
    try {
      let endpoint = `${apiUrl}/add-mentors`;
      let method = "POST";
      let successMessage = "";

      if (selectedMentorId) {
        endpoint = `${apiUrl}/update-mentors/${selectedMentorId}`;
        method = "PUT";
        successMessage = "Mentor updated successfully";
      } else {
        successMessage = "Mentor added successfully";
      }

      const response = await axios({
        method: method,
        url: endpoint,
        data: mentorFormData,
      });

      if (response.status === 201 || response.status === 200) {
        fetchMentors();
        setOpenDialog(false);
        toast.success(successMessage);
      } else {
        toast.error("Error adding or updating mentor");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error adding or updating mentor");
    }
  };

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://projectarthabackend.ajinkyatechnologies.com/api/search/search?query=${searchTerm}`
      );
      setMentors(response.data.mentor);
    } catch (error) {
      console.error("Error searching media", error);
      // setError("Error searching media. Please try again.");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title title={"Mentor Management"} className="mx-24"></Title>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
            width: "95%",
          }}
        >
          <div className="flex items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Mentor"
              className="mr-2 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleSearch}
              className="px-4 py-2 mx-4 font-medium text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
            >
              Search
            </button>
          </div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddMentor}
          >
            Add Mentor
          </Button>
        </div>

        <div className="flex justify-center">
          <TableContainer component={Paper} style={{ width: "90%" }}>
            <Table style={{ minWidth: 650 }} aria-label="mentors table">
              {/* <Table style={{ width: "80%" }} aria-label="mentors table"> */}

              <TableHead>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(90deg, #4F46E5 0%, #7A5CEB 100%)",
                  }}
                >
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Name
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Email
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Address
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Mobile Number
                  </TableCell>
                  <TableCell className="px-4 py-2" style={{ color: "white" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <Fade in={mentors} timeout={500}>
                <TableBody>
                  {mentors.length === 0 ? (
                    <TableRow style={{ height: "200px" }}>
                      <TableCell colSpan={5} align="center">
                        <h1 style={{ fontSize: "25px" }}>No Data Found</h1>
                      </TableCell>
                    </TableRow>
                  ) : (
                    mentors
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((mentor) => (
                        <TableRow key={mentor.mentor_id}>
                          <TableCell>{mentor.name}</TableCell>
                          <TableCell>{mentor.email}</TableCell>
                          <TableCell>{mentor.address}</TableCell>
                          <TableCell>{mentor.mobile_number}</TableCell>
                          <TableCell>
                            <Button
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              onClick={() => handleEditMentor(mentor.mentor_id)}
                            >
                              Edit
                              {/* <EditIcon /> */}
                            </Button>

                            <IconButton
                              aria-label="delete"
                              color="black"
                              onClick={() =>
                                handleDeleteMentor(mentor.mentor_id)
                              }
                            >
                              <DeleteIcon className="text-black" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                  )}
                </TableBody>
              </Fade>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={mentors.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            {selectedMentorId ? "Update Mentor" : "Add Mentor"}
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={mentorFormData.name}
              onChange={(e) => handleFormChange(e, "name")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20, marginTop: 20 }}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={mentorFormData.email}
              onChange={(e) => handleFormChange(e, "email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
            {!selectedMentorId && (
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={mentorFormData.password}
                onChange={(e) => handleFormChange(e, "password")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="primary" />
                    </InputAdornment>
                  ),
                }}
                style={{ marginBottom: 20 }}
              />
            )}
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              value={mentorFormData.address}
              onChange={(e) => handleFormChange(e, "address")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOn color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
            <TextField
              label="Mobile Number"
              variant="outlined"
              fullWidth
              value={mentorFormData.mobile_number}
              onChange={(e) => handleFormChange(e, "mobile_number")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone color="primary" />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: 20 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAddMentorSubmit} color="primary">
              {selectedMentorId ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Delete Mentor</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to delete this mentor?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDeleteMentor} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default MentorManagement;
