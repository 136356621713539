import "./impact.css";
import { Grid } from "@mui/material";
function Impact() {
  return (
    <>
      <div className="lg:min-h-screen impact-bg text-white text-center py-10">
        <Grid container>
          <Grid xs={12} md={6}>
            <h2 className="text-2xl lg:text-4xl mb-5 font-semibold">
              Completed Trips
            </h2>
            <div className="text-xl">
              <p>Completed trips related information </p>
              <p>Helped over 100 girls and 40 boys</p>
              <p> in 6 high schools across india </p>
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <h2 className="text-2xl lg:text-4xl font-semibold mb-5">
              Future Trips
            </h2>
            <div className="text-xl">
              <p>Future trips related information </p>
              <p>eg. Johannesburg, South Africa </p>
              <p> Lagos, Nigeria </p>
              <p>Bogota, Columbia</p>
              <p> Auroville, India </p>
              <p>Bijapur,India</p>
              <p>Dhaka, Bangladesh</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
export default Impact;
