import React, { useState } from 'react';
import { ThemeProvider, createTheme, Modal, Box, Typography, Card, CardContent, Button } from '@mui/material';

const Course = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1F0954', // Your primary color
      },
    },
  });

  const [openCourseModal, setOpenCourseModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [openResourceModal, setOpenResourceModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const courses = [
    { id: 1, title: 'Course Title 1', description: 'Description for Course 1', videos: [{ title: 'Video 1', duration: '10:30' }, { title: 'Video 2', duration: '15:45' }], resources: ['Resource 1', 'Resource 2'] },
    { id: 2, title: 'Course Title 2', description: 'Description for Course 2', videos: [{ title: 'Video 3', duration: '12:15' }, { title: 'Video 4', duration: '18:20' }], resources: ['Resource 3', 'Resource 4'] },
    // Add more courses as needed
  ];

  const handleOpenCourseModal = (course) => {
    setSelectedCourse(course);
    setOpenCourseModal(true);
  };

  const handleCloseCourseModal = () => {
    setOpenCourseModal(false);
  };

  const handleOpenResourceModal = () => {
    setOpenResourceModal(true);
  };

  const handleCloseResourceModal = () => {
    setOpenResourceModal(false);
  };

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%', // Adjust the width as needed
    maxWidth: '600px', // Maximum width for responsiveness
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
  };

  const videoCardStyles = {
    width: '200px',
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {courses.map((course) => (
            <Card key={course.id} style={{ margin: '20px', cursor: 'pointer' }} onClick={() => handleOpenCourseModal(course)}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {course.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {`${course.videos.length} Videos`}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Course Modal */}
      <Modal
        open={openCourseModal}
        onClose={handleCloseCourseModal}
        aria-labelledby="course-modal-title"
        aria-describedby="course-modal-description"
      >
        <Box sx={modalStyles}>
          <Typography id="course-modal-title" variant="h6" component="h2" align="center" gutterBottom>
            {selectedCourse && selectedCourse.title}
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {selectedCourse &&
              selectedCourse.videos.map((video, index) => (
                <Card key={index} style={videoCardStyles} onClick={handleOpenVideoModal}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {video.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Duration: {video.duration}
                    </Typography>
                  </CardContent>
                  <Button variant="contained" color="primary" onClick={handleOpenResourceModal}>
                    Resources
                  </Button>
                </Card>
              ))}
          </div>
        </Box>
      </Modal>

      {/* Resource Modal */}
      <Modal
        open={openResourceModal}
        onClose={handleCloseResourceModal}
        aria-labelledby="resource-modal-title"
        aria-describedby="resource-modal-description"
      >
        <Box sx={modalStyles}>
          <Typography id="resource-modal-title" variant="h6" component="h2" align="center" gutterBottom>
            Resources
          </Typography>
          <div>
            {selectedCourse &&
              selectedCourse.resources.map((resource, index) => (
                <Card key={index} style={videoCardStyles}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {resource}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
          </div>
        </Box>
      </Modal>

      {/* Video Player Modal */}
      <Modal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={modalStyles}>
          <Typography id="video-modal-title" variant="h6" component="h2" align="center" gutterBottom>
            Video Player
          </Typography>
          {/* Your customized video player goes here */}
          {/* Below the player, add a Resources tab */}
          <Typography variant="h6" gutterBottom>
            Resources
          </Typography>
          <div>
            {selectedCourse &&
              selectedCourse.resources.map((resource, index) => (
                <Card key={index} style={videoCardStyles}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {resource}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
};

export default Course;
